import Header from "../modules/Header";
import TableResultadoPoste from "../modules/TableResultadoPoste";

const ResultadoPoste = () => {

    return (
        <>
            <Header></Header>
            <h1>Resultado Deu no Poste (RJ)</h1>
            <TableResultadoPoste></TableResultadoPoste>
        </>
    );
}

export default ResultadoPoste;