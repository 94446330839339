import "./Header.css";
import logo from "../assets/logo.png"
import { useEffect, useRef, useState } from "react";

const Header = () => {

    const [submenuActive, setSubmenuActive] = useState(false);
    const refSubmenu = useRef();
    const refP = useRef();

    useEffect(() => {

        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {

            if (refSubmenu.current && !refSubmenu.current.contains(event.target) && refP.current && !refP.current.contains(event.target)) {
                setSubmenuActive(false);
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [refSubmenu]);

    return (
        <header>
            <a href="/">
                <img src={logo}></img>
            </a>
            <p ref={refP} onClick={() => setSubmenuActive(!submenuActive)}>Resultado por Estado</p>
            <a className="poste" href="/poste">Deu no Poste</a>
            <ul ref={refSubmenu} style={submenuActive ? { display: 'block' } : {}}>
                <li><a href="/GO">Goiás</a></li>
                <li><a href="/BA">Bahia</a></li>
                <li><a href="/CE">Ceará</a></li>
                <li><a href="/DF">Distrito Federal</a></li>
                <li><a href="/MG">Minas Gerais</a></li>
                <li><a href="/PB">Paraíba</a></li>
                <li><a href="/PR">Paraná</a></li>
                <li><a href="/PE">Pernambuco</a></li>
                <li><a href="/RJ">Rio de Janeiro</a></li>
                <li><a href="/RN">Rio Grande do Norte</a></li>
                <li><a href="/RS">Rio Grande do Sul</a></li>
                <li><a href="/SP">São Paulo</a></li>
                <li><a href="/SE">Sergipe</a></li>
            </ul>
        </header>
    )
}

export default Header;