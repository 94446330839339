import Header from "../modules/Header";
import TableResultadoEstado from "../modules/TableResultadoEstado";

const ResultadoDf = () => {

    return (
        <>
            <Header></Header>
            <h1>Resultado jogo do bicho DF de hoje (dia {new Date().getDate()} )</h1>
            <TableResultadoEstado state='DF'></TableResultadoEstado>
        </>
    );
}

export default ResultadoDf;