import Header from "../modules/Header";
import TableResultadoEstado from "../modules/TableResultadoEstado";

const ResultadoRs = () => {

    return (
        <>
            <Header></Header>
            <h1>Resultado jogo do bicho RS de hoje (dia {new Date().getDate()} )</h1>
            <TableResultadoEstado state='RS'></TableResultadoEstado>
        </>
    );
}

export default ResultadoRs;