import Header from "../modules/Header";
import TableResultadoEstado from "../modules/TableResultadoEstado";
import "./Resultado.css";

const ResultadoPb = () => {

    return (
        <>
            <Header></Header>
            <h1>Resultado jogo do bicho Paraíba de hoje (dia {new Date().getDate()} )</h1>
            <TableResultadoEstado state='PB'></TableResultadoEstado>
        </>
    );
}

export default ResultadoPb;