import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals.js';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ResultadoSp from './pages/ResultadoSp.js';
import ResultadoBa from './pages/ResultadoBa.js';
import ResultadoGo from './pages/ResultadoGo.js';
import ResultadoSe from './pages/ResultadoSe.js';
import ResultadoPb from './pages/ResultadoPb.js';
import ResultadoMg from './pages/ResultadoMg.js';
import ResultadoRn from './pages/ResultadoRn.js';
import ResultadoRs from './pages/ResultadoRs.js';
import ResultadoPr from './pages/ResultadoPr.js';
import ResultadoRj from './pages/ResultadoRj.js';
import ResultadoDf from './pages/ResultadoDf.js';
import ResultadoPe from './pages/ResultadoPe.js';
import ResultadoCe from './pages/ResultadoCe.js';
import ResultadoPoste from './pages/ResultadoPoste.js';
import Home from './pages/Home.js';
import Error from './pages/Error.js';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>

    <script async src="https://www.googletagmanager.com/gtag/js?id=AW-16556216049">
    </script>
    <script>
      window.dataLayer = window.dataLayer || [];
      {function gtag() { window.dataLayer.push(arguments); }}
      gtag('js', new Date());

      gtag('config', 'AW-16556216049');
    </script>
    <Router>
      <Routes>
        <Route path='/' element={<Home></Home>}></Route>
        <Route path='/SP' element={<ResultadoSp></ResultadoSp>}></Route>
        <Route path='/BA' element={<ResultadoBa></ResultadoBa>}></Route>
        <Route path='/GO' element={<ResultadoGo></ResultadoGo>}></Route>
        <Route path='/SE' element={<ResultadoSe></ResultadoSe>}></Route>
        <Route path='/PB' element={<ResultadoPb></ResultadoPb>}></Route>
        <Route path='/MG' element={<ResultadoMg></ResultadoMg>}></Route>
        <Route path='/RN' element={<ResultadoRn></ResultadoRn>}></Route>
        <Route path='/RS' element={<ResultadoRs></ResultadoRs>}></Route>
        <Route path='/PR' element={<ResultadoPr></ResultadoPr>}></Route>
        <Route path='/RJ' element={<ResultadoRj></ResultadoRj>}></Route>
        <Route path='/DF' element={<ResultadoDf></ResultadoDf>}></Route>
        <Route path='/PE' element={<ResultadoPe></ResultadoPe>}></Route>
        <Route path='/CE' element={<ResultadoCe></ResultadoCe>}></Route>
        <Route path='/poste' element={<ResultadoPoste></ResultadoPoste>}></Route>
        <Route path='/error' element={<Error></Error>}></Route>
      </Routes>
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
