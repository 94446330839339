import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Table.css";
import "./TableResultadoPoste.css";
import Loading from "./Loading";

const TableResultadoPoste = (prop) => {

    const [tableAtual, setTableAtual] = useState([]);
    const [tableAnterior, setTableAnterior] = useState([]);
    const [titleAtual, setTitleAtual] = useState('');
    const [titleAnterior, setTitleAnterior] = useState('');
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    const requestPoste = async () => {

        try {
            const data = await (await fetch(`${process.env.REACT_APP_BACKEND}`)).json();
            setTableAtual(data.oJogodobicho.resultAtual);
            setTableAnterior(data.oJogodobicho.resultAnterior);
            setTitleAtual(data.oJogodobicho.titleResultAtual);
            setTitleAnterior(data.oJogodobicho.titleResultAnterior);
            setLoading(true);
        } catch (e) {
            console.log(e);
            navigate('/error');
        }
    }

    useEffect(() => {

        requestPoste();
    }, [prop])

    return (
        <div className="posteContent">
            <Loading loading={loading}></Loading>
            <h1>{titleAtual}</h1>
            <table>
                {tableAtual.map(tr => {

                    return (
                        <tr>
                            {tr.map(td => <td>{td}</td>)}
                        </tr>
                    );
                })}
            </table>

            <h2 className="tableAnterior">{titleAnterior}</h2>
            <table>
                {tableAnterior.map(tr => {

                    return (
                        <tr>
                            {tr.map(td => <td>{td}</td>)}
                        </tr>
                    );
                })}
            </table>
        </div>
    );
}

export default TableResultadoPoste;