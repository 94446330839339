import { useNavigate } from "react-router-dom";
import Header from "../modules/Header"
import { useEffect } from "react";

import "./Error.css";

import maintenance from "../assets/maintenance.svg";

const Error = (prop) => {

    const navigate = useNavigate();

    const tryRequest = async () => {

        try {
            const data = await (await fetch(`${process.env.REACT_APP_BACKEND}`)).json();
            console.log(process.env.REACT_APP_BACKEND)
            if(data) {
                navigate('/');
            }
        } catch (e) {
            console.log(e);
            navigate('/error');
        }
    }

    useEffect(() => {

        tryRequest();
    }, [prop])

    return (
        <div className="errorContainer">
            <Header></Header>
            <p className="errorMessage">Estamos em Manutenção</p>
            <img className="error" src={maintenance}></img>
        </div>
    )
}

export default Error;