import Header from "../modules/Header";
import TableResultadoEstado from "../modules/TableResultadoEstado";
import "./Resultado.css"

const ResultadoBa = () => {

    return (
        <>
            <Header></Header>
            <h1>Resultado jogo do bicho Bahia de hoje (dia {new Date().getDate()} )</h1>
            <TableResultadoEstado state={'BA'}></TableResultadoEstado>
        </>
    );
}

export default ResultadoBa;