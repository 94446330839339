import Header from "../modules/Header";
import TableResultadoEstado from "../modules/TableResultadoEstado";

const ResultadoPe = () => {

    return (
        <>
            <Header></Header>
            <h1>Resultado jogo do bicho Pernambuco de hoje (dia {new Date().getDate()} )</h1>
            <TableResultadoEstado state='PE'></TableResultadoEstado>
        </>
    );
}

export default ResultadoPe;