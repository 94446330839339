
import loadingSvg from "../assets/loading.svg";

import "./Loading.css"

const Loading = (prop) => {

    return (
        !prop.loading ? <>
            <p className="titleLoading">Carregando...</p>
            <img className="loading" src={loadingSvg}></img>

        </> : <></>
    )
}

export default Loading;