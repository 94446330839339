import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Table.css";
import "./TableResultadoEstado.css";
import Loading from "./Loading";

const TableResultadoEstado = (prop) => {

    const [resultadoFacil, setResultadoFacil] = useState([]);
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    const requestPoste = async () => {

        try {
            const data = await (await fetch(`${process.env.REACT_APP_BACKEND}`)).json();
            setResultadoFacil(data.resultadofacil.data.filter(obj => obj.state == prop.state));
            setLoading(true);
        } catch (e) {
            console.log(e);
            navigate('/error');
        }
    }

    useEffect(() => {

        requestPoste();
    }, [prop])

    return (
        !resultadoFacil.length ?
            <div className="resultadoContent">
                <Loading loading={loading}></Loading>
                {loading && <p className="message">Infelizmente não temos resultados para hoje ({prop.state})</p>}
            </div>
            : <div className="resultadoContent">
                <Loading loading={loading}></Loading>
                {resultadoFacil.map((result, i) => {

                    return <>
                        <h2>{result.title}</h2>
                        <table>
                            {result.data.map(tr => {
                                return <tr>
                                    {tr.map(td =>
                                        <td>{td}</td>
                                    )}
                                </tr>
                            })}
                        </table>
                        {i > 0 && <div className="tableSpace"></div>}
                    </>
                })}
            </div>
    );
}

export default TableResultadoEstado;