import Header from "../modules/Header";
import TableResultadoEstado from "../modules/TableResultadoEstado";

const ResultadoMg = () => {

    return (
        <>
            <Header></Header>
            <h1>Resultado jogo do bicho MG de hoje (dia {new Date().getDate()} )</h1>
            <TableResultadoEstado state='MG'></TableResultadoEstado>
        </>
    );
}

export default ResultadoMg;