import Header from "../modules/Header";
import "./Home.css";

const Home = () => {

    return (
        <>
            <Header></Header>
            <h1 className="title">Jogo do Bicho</h1>
            <div className="contentHome">
                <p className="message">Este site está em permanente atualização!
                    Aqui você consegue acompanhar o resultado em vários estados, e também
                    o <a href="/poste">Deu no Poste</a>, que é o resultado do Rio de Janeiro,
                    valido na maioria das cidades do Brasil.
                    Temos resultados: <a href="/BA">Resultado Jogo Bicho BA</a>,
                    <a href="/CE">CE</a>, <a href="/DF">DF</a>, <a href="/GO">GO</a>,
                    <a href="/MG">MG</a>, <a href="/PB">PB</a>, <a href="/PR">PR</a>, <a href="/PE">PE</a>,
                    <a href="/RJ">RJ</a>, <a href="/RN">RN</a>, <a href="/RS">RS</a>, <a href="/SP">SP</a>,
                    <a href="/SE">SE</a>
                </p>
            </div>
        </>
    )
}

export default Home;